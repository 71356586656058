import 'babel-polyfill'
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";  
import adapter from "./util/adapter";
import './assets/font/font.css'

Vue.use(adapter);
Vue.config.productionTip = false; 

Vue.prototype.isShow = false;


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
